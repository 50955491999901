// Primary Colours
$yellow: #FFDC5B;
$black: #1A1A1A;
$primary: $yellow;
$link: $yellow;
$family-primary: 'Inter',
sans-serif;
$family-secondary: 'Roboto Condensed',
sans-serif;

// Navbar
$navbar-item-color: white;
$navbar-item-hover-color: white;
$navbar-item-img-max-height: 5rem;
$navbar-height: 10rem;

//Forms & Buttons
$breadcrumb-item-color: white;
$breadcrumb-item-separator-color: white;
$breadcrumb-item-hover-color: white;
$button-padding-vertical: 0.75rem;
$button-padding-horizontal: 1.5rem;
$control-radius: 0.5rem;
$hr-margin: 2.5rem 0;

//Content
$body-line-height: 1.6;
$content-heading-weight: bold;