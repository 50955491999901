body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

}

.site-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  background-color: $black;
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  border-top: 5px solid $yellow;
  overflow: hidden;
}

section,
article {
  width: 100%;
}

footer {
  z-index: 100;
  background-color: white;

  a {
    color: $black;
  }
}

.footer-primary {
  background-color: $yellow;
  padding: 2rem;

  @include desktop {
    margin-right: 3rem;
    padding: 5rem;
  }
}

.footer-container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  max-width: 1344px;

}


.footer-bold {
  letter-spacing: 2px;
  font-weight: bold;
  padding-bottom: 0.5rem;
  ;
}

.overlay-banner {
  background-color: $yellow;
  font-weight: bold;
  padding: 0.1rem 0.3rem;
  display: inline-block;
  position: absolute;
  top: 4rem;
  left: 4rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transform: rotate(10deg);
  font-size: 14px;
  transition: all .3s;

  @include mobile {
    right: 1rem;
    left: auto;
  }
}

.column:nth-of-type(2n+1) {
  .overlay-banner {
    transform: rotate(-10deg);
  }
}

.footer-meat {
  @include mobile {
    padding: 0rem 1rem;
  }

  .column {
    position: relative;

    @include mobile {
      &:not(:first-of-type) {
        border-top: 2px solid black;
      }

      padding-top: 2rem;
      padding-bottom: 2rem;
      ;
    }

    @include tablet {
      &:not(:first-of-type) {
        border-left: 2px solid black;
      }
    }

    @include desktop {
      &:hover {
        .overlay-banner {
          top: 3rem;
          transition: all .5s ease-in-out;
          transform: rotate(-90deg);
          left: -5rem;
          background-color: white;
        }
      }
    }
  }
}

.footer-smaller {
  font-size: 90%;
}

.feature-links {
  position: relative;
  margin-bottom: 4rem;

  a {
    background-color: $yellow;
    color: $black;
    display: inline-block;
    box-shadow: 0 18px 18px 0 rgba(0, 0, 0, 0.07);
    line-height: 1;
    transition: all .3s;

  }
}

.feature-link-1 {
  @include desktop {
    font-size: 3rem;
  }

  font-size: 1.5rem;
  transform: rotate(-4deg);
  padding: 0.5rem 1rem;

  &:hover {
    transform: rotate(-2deg) scale(1.1);
    transition: all .3s;
  }
}

.feature-link-2 {
  @include desktop {
    font-size: 2rem;
  }

  font-size: 1.5rem;
  transform: rotate(2deg);
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0px;
  bottom: -4rem;

  &:hover {
    transform: rotate(4deg) scale(1.1);
    transition: all .3s;
  }
}

h1 {
  color: $yellow;
  letter-spacing: 5px;
  padding-bottom: 3rem;

}

h2 {
  font-size: 2rem;

  @include tablet {
    font-size: 2.5rem;
  }

  line-height: 1.2;
  padding-bottom: 3rem;
  letter-spacing: -1px;
}

.content {
  padding-bottom: 3rem;
  position: relative;
}

.moon {
  position: absolute;
  overflow: hidden;
  left: 70%;
  top: 5%;
  height: 90%;
  animation-name: spin;
  animation-duration: 200s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @include mobile {
    top: 80%;
    right: 0px;
    left: 0px;
    bottom: auto;
    width: 100%;
    height: auto;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

img {
  opacity: 1;
  transition: opacity 3s;
}

img[data-src] {
  opacity: 0;
}